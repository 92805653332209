import VueI18n from "vue-i18n";

export interface ContractSigningState {
  requestId: number;

  contractSigningProcessLoading: boolean;

  contractSigningProgressLoading: boolean;
  contractSigningProgressPolling: ReturnType<typeof setTimeout> | null;

  yousignSignatureUrl: YousignSignatureUrl;
  contractSigningStatus: ContractSigningStatus | null;
  contractSigningStatusMessage: VueI18n.TranslateResult | null;

  contractName: ContractName;
  activationDate: string | null;
  signatureDate: string | null;
}

export interface GetContractSigningQuery {
  yousignSignatureUrl: YousignSignatureUrl;
  state: ContractSigningStatus | null;
  contractName: ContractName;
  activationDate: string | null;
  signatureDate: string | null;
}

export interface ContractSigning {
  yousignSignatureUrl: YousignSignatureUrl;
  contractSigningStatus: ContractSigningStatus | null;
  contractName: ContractName;
  activationDate: string | null;
  signatureDate: string | null;
}

export enum ContractSigningError {
  INVALID_ID = "INVALID_ID",
  EMAIL_NOT_VALIDATED = "EMAIL_NOT_VALIDATED",
  TERMS_AND_CONDITIONS_NOT_ACCEPTED = "TERMS_AND_CONDITIONS_NOT_ACCEPTED",
  SUBSCRIPTION_NOT_FOUND = "SUBSCRIPTION_NOT_FOUND",
  COMMUNICATION_FAILURE = "COMMUNICATION_FAILURE",
  CONTRACT_GENERATION_COMMUNICATION_FAILURE = "CONTRACT_GENERATION_COMMUNICATION_FAILURE",
  CONTRACT_GENERATION_FAILURE = "CONTRACT_GENERATION_FAILURE",
}

export enum ContractSigningStatus {
  PENDING = "PENDING",
  REFUSED = "REFUSED",
  SIGNED_CONTRACT_DOWNLOAD_FAILED = "SIGNED_CONTRACT_DOWNLOAD_FAILED",
  SIGNED_CONTRACT_PERSISTENCE_FAILED = "SIGNED_CONTRACT_PERSISTENCE_FAILED",
  CONFIRMATION_EMAIL_FAILED = "CONFIRMATION_EMAIL_FAILED",
  SIGNED = "SIGNED",
  PENDING_ACTIVATION = "PENDING_ACTIVATION",
  ACTIVATION_REQUESTED = "ACTIVATION_REQUESTED",
  GRID_EXTRACTION_ACTIVATED = "GRID_EXTRACTION_ACTIVATED",
}

export type YousignSignatureUrl = string | null;
export type ContractName = string | null;
